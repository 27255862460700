import { Field, Form, useFormikContext } from 'formik'
import { prop } from 'ramda'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import Header from 'views/Login/Header'
import Alert from 'views/shared/Alert'
import CheckboxField from 'views/shared/CheckboxField'
import InputField from 'views/shared/InputField'
import Link from 'views/shared/Link'
import PasswordInputField from 'views/shared/PasswordInputField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import Workspace from '../Workspace'

const FormContent = () => {
  const { status, isSubmitting } = useFormikContext()
  const workspace = useSelector(workspaceSelector)

  return (
    <Form>
      <Header />
      <Workspace workspace={workspace} />
      {prop('base', status) && <Alert className="auth__alert" message={prop('base', status)} />}
      <Field
        name="email"
        component={InputField}
        id="email"
        type="email"
        label={{ id: 'login.email' }}
        icon="alert"
        autoComplete="off"
        data-cy="email-input"
      />
      <Field
        name="password"
        component={PasswordInputField}
        id="password"
        label={{ id: 'login.password' }}
        icon="alert"
        autoComplete="off"
        visiblePassword
        data-cy="password-input"
      />
      <div className="auth__helpers mb-8">
        <Field
          className="mb-0"
          name="rememberMe"
          component={CheckboxField}
          id="rememberMe"
          label={{ id: 'login.rememberMe' }}
          data-cy="remember-me-checkbox"
        />
        <Link href="/reset_password" isRoot>
          <a className="main-link font-14" data-cy="login-forgot-password-link">
            <FormattedMessage id="login.forgotPassword" />
          </a>
        </Link>
      </div>
      <SubmitFormButton text={{ id: 'login.logIn' }} isDisabled={isSubmitting} isFullWidth dataCy="submit-log-in" />
    </Form>
  )
}

export default FormContent
