import { Formik } from 'formik'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'
import { useUnmount } from 'react-use'

import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import validationSchema from 'lib/yupLocalised/schemas/login'
import { setIsLoggedOutDueToInactivity as setIsLoggedOutDueToInactivityAction } from 'state/concepts/login/actions'
import { isLoggedOutDueToInactivitySelector } from 'state/concepts/login/selectors'
import { employeeLogin } from 'state/concepts/session/actions'
import { currentWorkspaceCustomDomainSelector } from 'state/concepts/session/selectors'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import { isStatusConnected } from 'utils/customDomains'
import Recaptcha from 'views/shared/Recaptcha'
import WorkspacePreview from 'views/shared/WorkspacePreview'
import Form from './Form'

const LoginForm = () => {
  const setIsLoggedOutDueToInactivity = useDispatchAction(setIsLoggedOutDueToInactivityAction)
  const handleSubmit = useFormSubmit(employeeLogin)
  const isLoggedOutDueToInactivity = useSelector(isLoggedOutDueToInactivitySelector)
  const workspace = useSelector(workspaceSelector)
  const customDomain = useSelector(currentWorkspaceCustomDomainSelector)
  const isCaptchaEnabled = !isStatusConnected(customDomain?.status)

  useUnmount(() => setIsLoggedOutDueToInactivity(false))

  return (
    <div className="login__content mb-40">
      <WorkspacePreview className="mb-32" />
      <Formik
        enableReinitialize
        initialValues={{
          email: '',
          password: '',
          rememberMe: false,
          workspaceId: prop('id', workspace),
        }}
        initialStatus={isLoggedOutDueToInactivity ? { base: { id: 'notifications.automaticLogoutProcessed' } } : {}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {isCaptchaEnabled ? (
          <Recaptcha action="workspaceLogin">
            <Form />
          </Recaptcha>
        ) : (
          <Form />
        )}
      </Formik>
    </div>
  )
}

export default LoginForm
